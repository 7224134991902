import React, {useEffect, useState} from 'react'
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import './i18n';
import Main from "./pages/main";
import './App.css';
import ScrollToTop from "react-scroll-to-top";

import ReactGA from 'react-ga';
import axios from 'axios';

const TRACKING_ID = "UA-86660902-6";
ReactGA.initialize(TRACKING_ID);

function App(props) {

    const detected = () => {
        try{
            axios.post('https://api.lukyanchuk.com/api/v2/public/detected', {
                'subDomain': window.location.host,
                'page':  localStorage.getItem('isAuth') ? 'resume' : 'auth',
            }).then(res => {
                console.log(res.data)
            }).catch(e => console.log(e))

        }catch (e) {
            alert(e)
            console.log(e)
        }
    }

    useEffect(() => {
        detected();
    }, []);

    return (
        <div id="bg-wrap">

            <Header />

            <div className="p-1">

                <Main/>

            </div>

            <Footer />

            <ScrollToTop smooth />
        </div>
    );
}

export default App;
//UA-86660902-6
