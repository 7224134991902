import React from 'react';
import {useTranslation} from "react-i18next";
import useAnalyticsEventTracker from "../../service/g-analytics/useAnalyticsEventTracjer";

function Language() {

    const { t, i18n } = useTranslation()

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
        gaEventTracker(event.target.value)
    }

    const gaEventTracker = useAnalyticsEventTracker('Language');

    return (<div>
        <div id="lang-switch">
            <div onChange={changeLanguage}>

                {
                    i18n.language === 'ua' ?
                        <label className="radio-img p-1 flag-language">
                            <input type="radio" value="en" name="language"/>
                            {/*<span>EN</span>*/}
                            <img src="/assets/img/lang/uk.png" alt="English" width="20" height={'13'}/>
                            <small className="text-secondary m-1">English</small>
                        </label>
                        :
                        <label className="radio-img p-1 flag-language">
                            <input type="radio" value="ua" name="language" defaultChecked/>
                            {/*<span>UA</span>*/}
                            <img src="/assets/img/lang/ua.png" alt="Ukraine" width="20" height={'13'}/>
                            <small className="text-secondary m-1">Українська</small>
                        </label>
                }

            </div>
        </div>
    </div>);
}

export default Language;
