import React, {useEffect, useState} from 'react';

import Auth from "./auth";
import {useTranslation} from "react-i18next";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracjer";
import SkillsSection from "../sections/skills";
import CertificatesSection from "../sections/certificates";
import MoreInfoSection from "../sections/more_info";
import EducationSection from "../sections/education";
import ExperienceSection from "../sections/experience";
import InfoSection from "../sections/info";
import SidebarSection from "../sections/sidebar";
import axios from "axios";


function Main(props) {

    const [isAuth, setIsAuth] = useState(localStorage.getItem('isAuth'));

    const {t, i18n} = useTranslation();

    const logout = (e) => {

        localStorage.clear();
        setIsAuth(false)
    }

    const gaEventTracker = (text) => {
    } // useAnalyticsEventTracker('Resume Social Media left-bar');

    // useEffect(() => {
    //     console.log('init')
    //     setIsAuth(localStorage.getItem('isAuth'))
    // }, [])

    if (!isAuth) {
        return <Auth/>;
    }

    return (

        <main className={'bg-dark'}>

            <div className="container row m-auto p-5">

                <div className="col-md-3">
                    <SidebarSection />
                </div>

                <div className="col-md-9">

                    <InfoSection />

                    <ExperienceSection />

                    <div className="d-flex flex-wrap">
                        <div className="col-md-6 col-12 p-1">

                        </div>

                        <div className="col-md-6 col-12 p-1">

                        </div>

                    </div>

                    <div className="d-flex flex-wrap">
                        <div className="col-md-6 col-12 p-1">

                            <SkillsSection />

                        </div>
                        <div className="col-md-6 col-12 p-1">

                            <EducationSection />

                        </div>

                    </div>

                    <CertificatesSection />

                    <MoreInfoSection />

                    <div className="text-center">
                        <a href="https://lukyanchuk.com/cooperation"
                           target={'_blank'}
                           className={'btn btn-light p-3 rounded-pill m-auto col-md-6 col-12 shadowPortfolio'}
                        >
                            {t('get.in.touch.title')}
                        </a>
                    </div>

                </div>

            </div>

        </main>
    );
}

export default Main;
