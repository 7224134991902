import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ApiSkills from "../service/api/api_skills";
import ApiCertificate from "../service/api/api_certificate";
import ApiExperience from "../service/api/api_experience";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import CardExperience from "../components/card/card_experience";

function ExperienceSection() {

    const { t, i18n } = useTranslation()

    const [data, setData] = useState([]);

    useEffect(()=> {

        axios.get(
            '/assets/files/Experience.json'
        ).then(function (response) {
            setData(response.data);
            console.log(data);
        }).catch(err => {
            // In case it errors.
            console.log(err);
        });

    }, []);

    return (<div id="experience-section">

        <div className="shadow p-4 mt-4 bg-white" style={{
            borderRadius: '20px'
        }}>
            <h2>

                {t('title.experience')}
            </h2>

            {/*<ApiExperience/>*/}

            <Accordion defaultActiveKey="1">

                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('job.full')}</Accordion.Header>
                    <Accordion.Body>
                        <div className={'d-flex flex-wrap'}>
                            {
                                data && data.filter((a) => a.work === (3 || 0)).map((item, index) => <CardExperience item={item} />)
                            }
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>{t('job.project')}</Accordion.Header>
                    <Accordion.Body>
                        {
                            data && data.filter((a) => a.work === 2).map((item, index) => <CardExperience item={item} />)
                        }
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>{t('job.part-time')}</Accordion.Header>
                    <Accordion.Body>
                        {
                            data && data.filter((a) => a.work === 1).map((item, index) => <CardExperience item={item} />)
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <hr/>
            <a href="https://lukyanchuk.com/portfolio" target={'_blank'} className={'nav-link'}>
                <small className="text-secondary">
                    <i className="bi-link-45deg" role="img" aria-label="link"></i> lukyanchuk.com/portfolio
                </small>
                <h5>{t('title.portfolio')}</h5>
            </a>

        </div>

    </div>);
}

export default ExperienceSection;

