import React from 'react';
import Section from "../section/section";
import {useTranslation} from "react-i18next";
import Language from "../language/language";
import Auth from "../../pages/auth";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from "react-bootstrap/DropdownButton";
function Header(props) {

    const {t, i18n} = useTranslation();

    const _isAuth = localStorage.getItem('isAuth');
    const _email = localStorage.getItem('email');

    const logout = () => {

        localStorage.clear();
        window.location.href = '/';
        // localStorage.setItem('isAuth', false)
    }

    return (
        <header className={'container-fluid'}>
            <div className="header-block mt-0 p-3 d-flex justify-content-between align-items-center">
                <div id={'logo'} className="col-md-auto text-start">
                    {/*<img src="https://y.lukyanchuk.com/assets/logo/logo.png" alt="logo"*/}
                    {/*     className={'img-fluid w-25'}/>*/}
                    <h5 className={'d-flex'}>
                        <span className={'lead'}>Y. L.</span>
                        <b className={'m-2 mt-0 mb-0'}>Resume</b>
                    </h5>
                </div>
                {/*<div className="title col-md-8 text-center p-md-0 p-5">*/}
                {/*    <h1 className={'display-2'}>*/}
                {/*        {t('welcome')}*/}
                {/*    </h1>*/}
                {/*</div>*/}
                <div id={'offer'} className="col-md-auto text-center">
                    {
                        !_isAuth ? <div className={'d-flex align-items-center'}>

                                <div className={'p-2'}><Language/></div>
                            </div>
                            : <div className={'d-flex align-items-center'}>

                                {/*<div className={'p-2'}><a href={'mailto:yarik@lukyanchuk.com?subject=JobOffer'}*/}
                                {/*                          target={'_blank'} className="btn btn-dark p-3 rounded-pill">*/}
                                {/*    {t('job.offer.title')}*/}
                                {/*</a>*/}
                                {/*</div>*/}
                                <img className={'rounded-circle'} width={'45'}
                                     src="https://p7.hiclipart.com/preview/336/946/494/avatar-user-medicine-surgery-patient-avatar-thumbnail.jpg"
                                     alt=""/>
                                {['end'].map(
                                    (direction) => (
                                        <DropdownButton
                                            key={direction}
                                            id={`dropdown-button-drop-${direction}`}
                                            drop={direction}
                                            variant="light"
                                            title={`${_email ?? 'Welcome'}`}
                                        >
                                            <Dropdown.Item eventKey="1">
                                                <small className={'text-secondary'}>{}</small>
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="2">
                                                <a href="mailto:yarik@lukyanchuk.com?subject=JobOffer" target={'_blank'}>
                                                    {t('job.offer.title')}
                                                </a>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item eventKey="3">
                                                <Language />
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item eventKey="4">
                                                <button className="btn text-danger" onClick={logout}>
                                                    {t('logout')}
                                                </button>
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    ),
                                )}

                            </div>
                    }

                </div>
            </div>
        </header>
    );
}

export default Header;
