import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Section from "../../components/section/section";
import {useTranslation} from "react-i18next";
import CardExperience from "../../components/card/card_experience";
import CardEducation from "../../components/card/card_education";

const ApiEducation = (props) => {

    const { t, i18n } = useTranslation();
    const [education, setEducation] = useState([]);
    const [load, setLoad] = useState(true);

    useEffect(()=> {

        axios.get(
            // 'https://storage.lukyanchuk.com/storage/json/education.json'
            'https://api.lukyanchuk.com/api/front-end/resume/education?sort=DESC',
        ).then(function (response) {
            const getEducation = response.data;
            setEducation(getEducation.data);
            console.log(getEducation);
            setLoad(false);
        }).catch(err => {
            // In case it errors.
            console.log(err);
        });

    }, []);

    return (
        load ? 'Loading...' :
        <div className={'d-flex flex-wrap'}>
            {
                education && education.slice(1, 4).map((item, index) => <CardEducation item={item} />)
            }
        </div>

    )

}

export default ApiEducation
