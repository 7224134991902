import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Section from "../../components/section/section";
import {useTranslation} from "react-i18next";
import CardEducation from "../../components/card/card_education";
import CardCertification from "../../components/card/card_certification";

const ApiCertificate = (props) => {

    const { t, i18n } = useTranslation();
    const [certificate, setSertificate] = useState([]);

    const [load, setLoad] = useState(true);

    useEffect(()=> {

        axios.get(
            'https://api.lukyanchuk.com/api/front-end/resume/certificate?data=DESC&limit=8'
        ).then(function (response) {
            const getSertificate = response.data.data;
            setSertificate(getSertificate);
            console.log(getSertificate);
            setLoad(false);
        }).catch(err => {
            // In case it errors.
            console.log(err);
        });

    }, []);

    return (

        load ? 'Loading...' :
        <div className={'d-flex flex-wrap'}>
            {
                // certificate.slice(0, 3)
                certificate && certificate.map((item, index) => <CardCertification item={item} />)
            }

            <a href="https://yaroslav.lukyanchuk.com/certificates"
               target={'_blank'}
               className={'btn btn-dark p-3 rounded-pill m-auto col-md-6 col-12 shadowPortfolio'}
            >
                {t('title.view-more')}
            </a>

        </div>

    )

}

export default ApiCertificate
